import React from "react";
import PropTypes from "prop-types";

export default function Mosaic({ children }) {
  return (
    <div className="w-full md:w-1/2 lg:w-1/3 bg-green-400 text-center">
      <div className="aspect-w-1 aspect-h-1">{children}</div>
    </div>
  );
}

Mosaic.propTypes = {
  children: PropTypes.node.isRequired,
};
