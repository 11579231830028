import React from 'react';
import Container from '../layout/Container';

//images
import profile from '../../images/profile.png';

export default function About() {
  return (
    <section className="bg-white text-green-800">
      <div className="py-12">
        <Container>
          <div className="text-center mb-12">
            <h1 className="text-sm text-green-600 font-semibold uppercase mb-4">
              Marianne Marschollek, Heilpädagogin
            </h1>
            <h1 className="font-serif text-4xl sm:text-6xl">Über mich</h1>
          </div>
          <div className="flex items-center justify-center px-5 py-5">
            <div className="bg-gradient-to-br from-green-600 to-green-800 text-white rounded-2xl shadow-md w-full lg:w-10/12 xl:w-3/4 overflow-hidden">
              <div className="flex flex-wrap items-center">
                <div className="w-full sm:w-1/2 md:w-2/5 bg-green-200 text-center">
                  <img src={profile} className="h-full w-full object-cover" />
                </div>
                <div className="w-full sm:w-1/2 md:w-3/5 px-3 text-left">
                  <div className="p-5 xl:px-8 md:py-5">
                    <h3 className="text-2xl">Hallo, ich bin Marianne!</h3>
                    <h5 className="text-xl mb-3">
                      Staatlich anerkannte Heilpädagogin (B.A.) und Erzieherin.
                    </h5>
                    <p className="text-sm text-green-200 mb-4">
                      Seit vielen Jahren widme ich mich in meiner täglichen
                      Arbeit der Förderung von Kindern, der Entfaltung ihrer
                      Möglichkeiten und der positiven Gestaltung ihres
                      Lebensumfeldes. Mit meiner mobilen heilpädagogischen
                      Praxis richte ich mich an Kinder vom Säuglings- bis zum
                      Einschulungsalter, die behindert oder von einer
                      Behinderung bedroht sind.
                    </p>
                    <a href="#" className="text-sm">
                      &#8594;{' '}
                      <a
                        href="mailto:marianne@mobile-heilpaedagogik.de"
                        className="underline"
                      >
                        Kontakt aufnehmen
                      </a>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </section>
  );
}
