import React from "react";
// import Container from "../layout/Container";

//images
import hero from "../../images/hero.jpg";

export default function Hero() {
  return (
    <section className="h-screen md:h-screen-2/3 pt-20">
      <div className="flex md:hidden absolute bottom-0 left-0 w-full z-10 justify-center items-center py-4 text-white">
        Nach unten scrollen
      </div>
      <div className="relative bg-gradient-to-b from-green-800 to-green-700 h-full w-full text-white flex flex-wrap justify-end items-center">
        <div className="w-full md:w-1/2 p-8">
          <div className="max-w-3xl">
            <h1 className="font-serif text-4xl font-thin mb-4">
              Ich unterstütze, berate und begleite...
            </h1>
            <p className="text-green-300 font-semibold">
              ... Familien mit ihren Kindern in verschiedensten
              Problemsituationen. Dabei möchte ich den Fokus auf vorhandene
              Ressourcen und Fähigkeiten richten, um eine zufriedene gemeinsame
              Lebensweise zu gestalten.
            </p>
          </div>
        </div>
        <div className="w-full md:w-1/2 2xl:w-1/3 bg-green-200 h-screen-1/2 md:h-full">
          <img src={hero} className="h-full w-full object-cover" />
        </div>
      </div>
    </section>
  );
}
