import React from "react";

import Layout from "../components/layout/Layout";
import SEO from "../components/misc/seo";

// Sections
import Hero from "../components/sections/Hero";
import About from "../components/sections/About";
import Mosaic from "../components/sections/Mosaic";

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Home"
      />
      <Hero />
      <About />
      <Mosaic />
    </Layout>
  );
}

export default IndexPage;
