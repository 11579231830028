import React from "react";
import Container from "../layout/Container";
import Card from "../cards/Mosaic";

// images
import balloons from "../../images/balloons.jpg";
import bricks from "../../images/bricks.jpg";
import mask from "../../images/mask.jpg";

export default function Mosaic() {
  return (
    <section className="">
      <div className="relative bg-green-900 flex flex-wrap items-center text-white">
        <Container>
          <div className="px-10 my-12">
            <h1 className="text-8xl font-serif font-thin">Ziele</h1>
          </div>
          <div className=" p-8 md:p-0">
            <div className="flex flex-wrap shadow-lg rounded-2xl overflow-hidden">
              <Card>
                <div className="flex flex-col p-8 justify-center bg-green-700">
                  <h1 className="font-serif text-2xl mb-4">Freude haben</h1>
                  <p className="text-green-200 font-light">
                    Ich möchte die gesellschaftliche Teilhabe verbessern und die
                    Lebensqualität erhöhen.
                  </p>
                </div>
              </Card>
              <Card>
                <img src={mask} className="h-full w-full object-cover" />
              </Card>
              <Card>
                <div className="flex flex-col p-8 justify-center bg-green-800">
                  <h1 className="font-serif text-2xl mb-4">
                    Fähigkeiten nutzen
                  </h1>
                  <p className="text-green-300 font-light">
                    Ich möchte dabei helfen, Ressourcen und Kompetenzen zu
                    identifizieren und zu erweitern.
                  </p>
                </div>
              </Card>
              <Card>
                <img src={bricks} className="h-full w-full object-cover" />
              </Card>
              <Card>
                <div className="flex flex-col p-8 justify-center bg-green-500">
                  <h1 className="font-serif text-2xl mb-4">Weitergehen</h1>
                  <p className="text-green-100 font-light">
                    Ich möchte Kinder und Eltern befähigen, entscheidende
                    Schritte selbst zu gehen.
                  </p>
                </div>
              </Card>
              <Card>
                <img src={balloons} className="h-full w-full object-cover" />
              </Card>
            </div>
          </div>
        </Container>
      </div>
    </section>
  );
}
